<template>
    <div id="login-container" class="container" :class="[$mq, {auditor: isAuditor}]">
        <template v-if="isAuditor && !isKeyBoardOpen">
            <div class="login-topbar">
                <div class="location-card">
                    <div class="name">{{ auditorLogged.name }} {{ auditorLogged.surname }}</div>
                    <div class="logout" @click="auditorLogout()"></div>
                </div>
            </div>
        </template>
        <div class="logo" v-if="!isKeyBoardOpen"></div>
        <div class="site-connect">
            <div class="site-connect-title" v-if="!isKeyBoardOpen">{{ $t('access.select_site') }}</div>
            <SearchBar v-if="sites.length > 4" :search="search" @on-search="searchBarInput" @on-button-click="cleanSearch" :placeholder="$t('access.search_placeholder')" :isAuditor="isAuditor" @on-focus="checkFocusKeyboard" @on-blur="checkBlurKeyboard"/>
            <div class="sites-container" :class="[{'is-keyboard-open': isKeyBoardOpen}, {'auditor': isAuditor}]">
                <div class="history-sites">
                    <div class="row-site" v-for="(site, index) in filteredSites" :key="index">
                        <div v-if="!isAuditor" class="remove" @click="remove(site)"></div>
                        <div class="card-site" @click="loginSite(site, $event)">
                            <div class="logo-site">
                                <div class="account-logo" :style="{ backgroundImage: 'url(' + site.account_logo + ')' }"></div>
                                <!-- <img :src="site.account_logo" class="account-logo" /> -->
                            </div>
                            <div class="text-content" v-if="!isAuditor">
                                <div class="site-card-title">{{ site.account_name }}</div>
                                <div class="site-card-description">{{ site.location_name }}</div>
                            </div>
                            <div class="text-content" v-else>
                                <div class="site-card-title">{{ site.location_name }}</div>
                                <div class="site-card-description">{{ site.identification ? site.identification : '' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!isAuditor" class="row-site" :class="'add-site'">
                    <div class="remove"></div>
                    <div class="card-site" @click="goToNormalLogin()">
                        <div class="logo-site">
                            <img :src="css_image_url + 'add_site.svg'" class="account-logo" />
                        </div>
                        <div class="text-content">
                            <div class="site-card-title">{{ $t('access.new_site') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="login-bottom">
            <Copyright customClass="login-copyright"></Copyright>
            <div class="version">{{ $t('tooltip.version') }}: {{ appVersion }}</div>
        </div>
    </div>
</template>

<script>
import Copyright from '@/components/layout/Copyright'
import mixin from '@/core/mixin'
import SearchBar from '../ui/SearchBar.vue'

export default {
    components: {
        Copyright,
        SearchBar
    },
    data() {
        return {
            searchBar: false,
            search: '',
            timerSearch: false,
            show_more: false,
            isKeyBoardOpen: false
        }
    },
    name: 'MultiAccountLogin',
    props: {
        sites: {
            type: Array,
            default: false
        },
        isAuditor: {
            type: Boolean,
            default: false
        }
    },
    mixins: [mixin],
    computed: {
        appVersion() {
            return process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : ''
        },
        css_image_url() {
            return css_image_url
        },
        filteredSites() {
            if (this.search) {
                return this.sites.filter((site) => {
                    // Filter the sites based on the search input
                    return site.account_name.toLowerCase().includes(this.search.toLowerCase()) || site.location_name.toLowerCase().includes(this.search.toLowerCase())
                })
            } else {
                return this.sites
            }
        },
        auditorLogged() {
            return this.$store.getters['loginUser/getAuditor']
        },
        home() {
            return this.$store.getters['getHome'] ? this.$store.getters['getHome'] : '/home'
        }
    },
    methods: {
        checkFocusKeyboard() {
            if(this.$mq == 'portrait' && this.isAuditor) {
                this.isKeyBoardOpen = true
            }else {
                this.isKeyBoardOpen = false
            }
        },
        checkBlurKeyboard(e) {
            const self = this
            setTimeout(() => {
                if(self.$mq == 'portrait' && self.isAuditor) {
                    if(self.isKeyBoardOpen){
                        self.isKeyBoardOpen = false
                    }
                }
            }, 500)
            
        },
        goToNormalLogin() {
            this.$emit('goToNormalLogin')
        },
        loginSite(site, e) {
            e.preventDefault()
            e.stopPropagation()

            if (fnCheckConnection()) {
                this.$emit('loginSite', site)
            } else {
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('login.no_internet') })
            }
        },
        remove(site) {
            var self = this
            this.$popup.delete({
                message: self.$t('login.popup_delete', { s: site.account_name + ' - ' + site.location_name }),
                title: self.$t('popup.confirm.title'),
                textSave: self.$t('menu_aux.action.delete'),
                textCancel: self.$t('menu_aux.action.cancel'),
                callSave: function () {
                    self.$emit('removeSite', site)
                }
            })
        },
        auditorLogout() {
            const self = this
            self.$popup.disconnect({
                message: self.$t('login.logout_auditor_desc', { user: self.auditorLogged.name }),
                textSave: self.$t('popup.local_logout.text_save'),
                textCancel: self.$t('popup.local_logout.text_cancel'),
                callSave: function () {
                    // self.$router.push('/login')
                    self.$store.dispatch('login/logout', { employeeId: self.auditorLogged.employee_id }).then(function () {
                        self.$store.dispatch('login/clearLoginData').then(() => {
                            if (false && process.env.VUE_APP_IS_APP == 'TRUE') {
                            } else {
                                self.$router.push('/login').then(function () {
                                    location.reload()
                                })
                            }
                        })
                    })
                }
            })
        },
        searchBarInput(e) {
            const self = this
            if (this.timerSearch) {
                clearTimeout(this.timerSearch)
                this.timerSearch = null
            }
            this.timerSearch = setTimeout(() => {
                self.search = e.target.value
            }, 800)
        },
        cleanSearch() {
            this.search = ''
        }
    }
}
</script>

<style lang="scss">
#login-container.container {
    $login-topbar-height: $menu-top-height;
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(space-between);
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0 auto;

    .logo {
        @include background($image: img('logo_andy_small.png'), $position: center center);
        width: 100%;
        height: 30px;
    }
    .login-topbar {
        @include display-flex();
        @include justify-content(flex-end);
        @include align-items(flex-start);
        width: 100%;
        text-align: center;
        height: $login-topbar-height;
        padding-top: $alex-app-padding;

        .location-card {
            @include display-flex();
            @include border-radius(4px);
            @include align-items(center);
            background-color: #fff;
            box-shadow: 0px 0px 10px rgba(159, 162, 166, 0.498039);
            height: $menu-top-tools-height;
            z-index: 2;

            .name {
                @include font-size(m);
                @include text-ellipsis();
                padding-left: 8px;
                color: #222;
                display: inline-block;
                width: fit-content;
                font-family: $conden-bold;
                height: rem(30px);
                line-height: rem(30px);
                max-width: 230px;
            }
            .logout {
                @include border-radius(50%);
                @include background($image: img('power_off_error.svg'), $size: 30px);
                background-color: $error-t70;
                width: 40px;
                height: 40px;
                margin: 0px 10px;
                cursor: pointer;
            }
        }
    }
    .site-connect {
        height: calc(100% - 70px);
        width: 100%;
        overflow: hidden;

        .search-container {
            padding-left: 34px;
            padding-right: 6px;
            .close {
                right: 18px;
            }
        }
        .search-auditor {
            padding-left: 0px;
        }

        .site-connect-title {
            @include font-size(sm);
            font-family: $text-bold;
            color: $info-s80;
            text-align: center;
            padding: 18px 0px;
        }

        .sites-container {
            @include display-flex();
            @include flex-direction(column);
            width: 100%;
            height: calc(100% - 60px);

            .history-sites::-webkit-scrollbar-track {
                background: #ffffff;
            }

            .history-sites::-webkit-scrollbar-thumb {
                background: #b3b3b3;
            }
            .history-sites {
                max-height: calc(100% - 160px);
                overflow-y: auto;
                overflow-x: hidden;
                // padding: 8px;
            }
            .row-site {
                width: 100%;
                height: 75px;
                min-height: 75px;
                @include display-flex();
                @include flex-direction(row);
                @include justify-content(space-between);
                gap: 12px;
                margin-bottom: 8px;

                .remove {
                    @include background($image: img('remove.svg'), $position: center center);
                    @include display-flex();
                    @include align-self(center);
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }

                .card-site {
                    @include display-flex();
                    @include flex-direction(row);
                    @include align-items(center);
                    @include justify-content(flex-start);
                    @include border-radius(4px);
                    @include background($image: img('right_neutro.svg'), $position: right 10px center, $size: 15px);
                    width: 100%;
                    height: 100%;
                    padding: 8px;
                    margin-right: 6px;

                    background-color: #fff;
                    cursor: pointer;

                    &:hover {
                        background-color: hover(#fff);
                    }

                    .logo-site {
                        @include display-flex();
                        @include justify-content();
                        width: 70px;
                        height: 100%;

                        .account-logo {
                            @include background($position: center center, $size: contain);
                            width: 100%;
                            height: auto;
                        }
                    }

                    .text-content {
                        @include display-flex();
                        @include flex-direction(column);
                        padding-left: 6px;
                        width: calc(100% - 95px);
                        .site-card-title {
                            @include text-ellipsis();
                            @include font-size(xs);
                            font-family: $text-bold;
                            color: $inactive-s90;
                        }
                        .site-card-description {
                            @include text-ellipsis();
                            @include font-size(xs);
                            font-family: $text;
                            color: $inactive-s90;
                        }
                    }
                }
                &.add-site {
                    margin-top: 8px;

                    .remove {
                        background: border-box;
                        pointer-events: none;
                    }
                    .site-card-title {
                        text-transform: uppercase !important;
                        font-family: $text !important;
                    }
                }
            }

            .row-site:last-child {
                margin-bottom: 0px;
            }
        }
    }
    .login-bottom {
        margin-top: 0px !important;
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);

        .version {
            @include font-size(sm);
            font-family: $text-medium;
            color: $color-neutral-600;
            line-height: 18px;
            max-width: 50%;
            height: auto;
            margin-bottom: 6px;
            padding: 0px 10px;
        }
    }

    &.landscape {
        max-width: 700px;

        .site-connect-title {
            font-size: 22px !important;
        }
        .logo-site {
            width: 100px !important;
        }
        .text-content {
            width: calc(100% - 130px) !important;

            .site-card-title {
                font-size: 18px !important;
            }
            .site-card-description {
                font-size: 16px !important;
            }
        }
    }

    &.desktop {
        max-width: 700px;

        .site-connect-title {
            @include font-size(l);
        }
        .logo-site {
            width: 100px !important;
        }
        .text-content {
            width: calc(100% - 130px) !important;

            .site-card-title {
                font-size: 18px !important;
            }
            .site-card-description {
                font-size: 16px !important;
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
#login.login-organization.portrait {
    .search-container {
        .close {
            top: 10px;
        }
    }
    input[type='search']::placeholder {
        margin-left: 55px;
    }

    #login-container.container {
        .sites-container {
            .row-site {
                .card-site {
                    min-width: calc(100% - 42px);
                }
                &.add-site {
                    min-width: calc(100% - 8px);
                }
            }

            .history-sites {
                max-height: calc(100% - 160px);
            }
        }
    }
}

#login-container.container.portrait {
    padding: 9px 12px;
    $topbar-height: 60px;

    &.auditor{
        .login-topbar{
            margin-bottom: 16px;
            .location-card{
                height: calc(60px - 16px);

                .logout{
                    width: 35px;
                    height: 35px;
                }
            }
        }
        .history-sites{
            max-height: calc(100% - 117px);
        }
    }

    .login-topbar {
        // @include justify-content(left);
        width: 100%;
        position: relative;

        $location-card-height: calc(#{$topbar-height} - 5px);
        .location-card {
            @include display-flex();
            @include border-radius(3px);
            @include align-items(center);
            box-shadow: none;
            height: $location-card-height;
            max-height: unset;
            padding: 0px 0 0 7px;

            .name {
                @include font-size(s);
                @include text-ellipsis();
                font-family: $text-bold;
                padding-left: 5px;
                padding-right: 5px;
                color: #222;
                display: inline-block;
                height: $location-card-height;
                line-height: 60px;
            }
        }
    }

    .login-bottom {
        margin-top: 0px !important;
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);

        .version {
            @include font-size(xs);
            font-family: $text-medium;
            color: $color-neutral-600;
            line-height: 18px;
            max-width: 50%;
            height: auto;
            margin-bottom: 6px;
            padding: 0px 10px;
        }
    }

    .sites-container{
        &.auditor.is-keyboard-open {
            height: calc(100% - 10px);
        }
    }
}
</style>
